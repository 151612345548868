<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Total Monthly Requests" type="gradient-red" :sub-title="'' + stats.total_monthly_requests_approved" icon="ni ni-active-40" class="mb-4 mb-xl-0">
            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> {{ stats.total_monthly_requests }}</span>
              <span class="text-nowrap">Total Requests</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Total Weekly Requests" type="gradient-orange" :sub-title="'' + stats.total_weekly_requests_approved" icon="ni ni-chart-pie-35" class="mb-4 mb-xl-0">
            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> {{ stats.total_weekly_requests }}</span>
              <span class="text-nowrap">Total Requests</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Todays Requests (Morning)" type="gradient-green" :sub-title="'' + stats.total_todays_requests_morning_approved" icon="ni ni-money-coins" class="mb-4 mb-xl-0">
            <template slot="footer">
              <span class="text-danger mr-2"><i class="fa fa-arrow-up"></i> {{ stats.total_todays_requests_morning }}</span>
              <span class="text-nowrap">Total Requests</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Todays Requests (evening)" type="gradient-info" :sub-title="'' + stats.total_todays_requests_evening_approved" icon="ni ni-chart-bar-32" class="mb-4 mb-xl-0">
            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> {{ stats.total_todays_requests_evening }}</span>
              <span class="text-nowrap">Total Requests</span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 mb-5">
          <calendar-widget></calendar-widget>

          <card type="default" header-classes="bg-transparent" v-if="false">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>
                <h5 class="h3 text-white mb-0">Sales value</h5>
              </div>
              <div class="col">
                <ul class="nav nav-pills justify-content-end">
                  <li class="nav-item mr-2 mr-md-0">
                    <a class="nav-link py-2 px-3" href="#" :class="{ active: bigLineChart.activeIndex === 0 }" @click.prevent="initBigChart(0)">
                      <span class="d-none d-md-block">Month</span>
                      <span class="d-md-none">M</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link py-2 px-3" href="#" :class="{ active: bigLineChart.activeIndex === 1 }" @click.prevent="initBigChart(1)">
                      <span class="d-none d-md-block">Week</span>
                      <span class="d-md-none">W</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <line-chart :height="350" ref="bigChart" :chart-data="bigLineChart.chartData" :extra-options="bigLineChart.extraOptions"> </line-chart>
          </card>
          <card header-classes="bg-transparent" v-if="false">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">Performance</h6>
                <h5 class="h3 mb-0">Total orders</h5>
              </div>
            </div>

            <bar-chart :height="350" ref="barChart" :chart-data="redBarChart.chartData"> </bar-chart>
          </card>
        </div>
      </div>
      <!-- End charts-->

      <!--Tables-->
      <div class="row mt-5" v-if="false">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <page-visits-table></page-visits-table>
        </div>
        <div class="col-xl-4">
          <social-traffic-table></social-traffic-table>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";

// Tables
import SocialTrafficTable from "./Dashboard/SocialTrafficTable";
import PageVisitsTable from "./Dashboard/PageVisitsTable";

import CalendarWidget from "./Dashboard/CalendarWidget";

import axios from "axios";

export default {
  components: {
    LineChart,
    BarChart,
    PageVisitsTable,
    SocialTrafficTable,
    CalendarWidget,
  },
  data() {
    return {
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [],
          labels: [],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      redBarChart: {
        chartData: {
          labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [
            {
              label: "Sales",
              data: [25, 20, 30, 22, 17, 29],
            },
          ],
        },
      },
      stats: {
        total_monthly_requests: 0,
        total_monthly_requests_approved: 0,

        total_weekly_requests: 0,
        total_weekly_requests_approved: 0,

        total_todays_requests_morning: 0,
        total_todays_requests_morning_approved: 0,

        total_todays_requests_evening: 0,
        total_todays_requests_evening_approved: 0,
      },
    };
  },
  methods: {
    initBigChart(index) {
      let chartData = {
        datasets: [
          {
            label: "Performance",
            data: this.bigLineChart.allData[index],
          },
        ],
        labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      };
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },
    async fetchData() {
      const data = JSON.stringify(this.model);
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: process.env.VUE_APP_API_BASE_URL + "/access/fetch-visitor-stats",
        data: data,
      }).then(
        (response) => {
          // console.log(response);
          if (response.data.success == true) {
            this.stats = response.data.data;
            // console.log("this.tableData", this.tableData);
          }
        },
        (error) => {
          console.log("ERROR", error.response.data);
          this.$notify({
            type: "danger",
            message: error.response.data.message,
          });
        }
      );
    },
  },
  mounted() {
    this.initBigChart(0);
    this.fetchData();
  },
};
</script>
<style></style>
