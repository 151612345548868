<template>
  <div class="row justify-content-center">
    <div class="col-lg-8 col-md-8">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-5" v-if="false">
          <div class="text-muted text-center mt-2 mb-3">
            <small>Sign in with</small>
          </div>
          <div class="btn-wrapper text-center">
            <a href="#" class="btn btn-neutral btn-icon mr-4">
              <span class="btn-inner--icon"><img src="/img/icons/common/github.svg"/></span>
              <span class="btn-inner--text">Github</span>
            </a>
            <a href="#" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon"><img src="/img/icons/common/google.svg"/></span>
              <span class="btn-inner--text">Google</span>
            </a>
          </div>
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <h1>Pratishthan Visit Registration</h1>
          </div>
          <p>
            Sahaja Yogi Visitors above 65 years, children below 10 years and pregnant women are not permitted to visit.<br><br>

            Govt Regulations of Covid 19 Protocol & Social Distancing to be followed.
          </p>
          <form role="form" @submit.prevent="handleSubmit()" v-if="qr_code_token == ''" style="display:block;">
            <label for="">Name*</label>
            <base-input alternative class="input-group-alternative mb-3" placeholder="Name" addon-left-icon="ni ni-single-02" v-model="model.name" name="Name"> </base-input>
            <validation-error :errors="apiValidationErrors.name" />

            <label for="">Date of Birth*</label>
            <base-input>
              <flat-picker
                alternative
                type="date"
                class="input-group-alternative mb-3 form-control datepicker"
                placeholder="Date of Visit"
                addon-left-icon="ni ni-calendar-grid-58"
                v-model="model.dob"
                name="dob"
                slot-scope="{ focus, blur }"
                @on-open="focus"
                @on-close="blur"
                :config="configBirthDate"
              >
              </flat-picker>
            </base-input>
            <validation-error :errors="apiValidationErrors.dob" />

            <label for="">Email*</label>
            <base-input alternative class="input-group-alternative mb-3" placeholder="Email" addon-left-icon="ni ni-email-83" v-model="model.email" name="Email"> </base-input>
            <validation-error :errors="apiValidationErrors.email" />

            <label for="">Mobile Number*</label>
            <base-input alternative class="input-group-alternative mb-3" placeholder="Phone" addon-left-icon="ni ni-mobile-button" v-model="model.phone" name="Phone"> </base-input>
            <validation-error :errors="apiValidationErrors.phone" />

            <label for="">Aadhar Number*</label>
            <base-input alternative class="input-group-alternative mb-3" placeholder="12 Digit Aadhar Number" addon-left-icon="ni ni-collection" v-model="model.aadhar" minlength="0" maxlength="12" name="Aadhar"> </base-input>
            <validation-error :errors="apiValidationErrors.aadhar" />

            <label for="">Country*</label>

            <base-input alternative>
              <select class="form-control alternative input-group-alternative" v-model="model.country" placeholder="Select Country">
                <option :key="option.name" v-for="option in countryOptions">
                  {{ option.name }}
                </option>
              </select>
            </base-input>
            <validation-error :errors="apiValidationErrors.country" />

            <label for="">State*</label>

            <base-input alternative class="input-group-alternative mb-3" placeholder="State / Province" addon-left-icon="ni ni-map-big" v-model="model.state" name="State"> </base-input>
            <validation-error :errors="apiValidationErrors.state" />

            <label for="">City*</label>

            <base-input alternative class="input-group-alternative mb-3" placeholder="City" addon-left-icon="ni ni-map-big" v-model="model.city" name="City"> </base-input>
            <validation-error :errors="apiValidationErrors.city" />

            <label for="">Complete Address*</label>

            <base-input alternative class="input-group-alternative mb-3" placeholder="Address" addon-left-icon="ni ni-building" v-model="model.address" name="Address"> </base-input>
            <validation-error :errors="apiValidationErrors.address" />

            <label for="">Center Name*</label>

            <base-input alternative class="input-group-alternative mb-3" placeholder="Center Name" addon-left-icon="ni ni-world-2" v-model="model.center_name" name="Center Name"> </base-input>
            <validation-error :errors="apiValidationErrors.center_name" />

            <label for="">Center Co-ordinator Name*</label>

            <base-input alternative class="input-group-alternative mb-3" placeholder="Center Co-ordinator Name" addon-left-icon="ni ni-circle-08" v-model="model.center_coo" name="Center Co-ordinator Name"> </base-input>
            <validation-error :errors="apiValidationErrors.center_coo" />

            <label>Date of Visit*</label>
            <base-input>
              <flat-picker
                alternative
                type="date"
                class="input-group-alternative mb-3 form-control datepicker"
                placeholder="Date of Visit"
                addon-left-icon="ni ni-calendar-grid-58"
                v-model="model.date_visit"
                name="date_visit"
                slot-scope="{ focus, blur }"
                @on-open="focus"
                @on-close="blur"
                :config="configVisitDate"
                minDate="2021-10-08"
              >
              </flat-picker>
            </base-input>
            <validation-error :errors="apiValidationErrors.date_visit" />

            <label style="display: block; margin-bottom: 15px;">Time Slot*</label>

            <base-radio :key="slot.value" v-for="slot in timeSlotOptions" :name="slot.value" class="mb-3" v-model="model.time_slot">{{ slot.text }}</base-radio>

            <fieldset style="margin-top: 20px;">
              <legend class="scheduler-border">Accompanied*</legend>
              <button class="btn btn-sm btn-default" @click.prevent="addAccompanied">Add Person</button>

              <div :key="person.id" v-for="(person, index) in model.accompaniedObj">
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <label>Name*</label>
                    <base-input alternative class="input-group-alternative float-left mb-3 p-0" placeholder="Name" addon-left-icon="ni ni-single-02" v-model="person.name" name="Name" required> </base-input>
                  </div>
                  <div class="col-md-6">
                    <label>Phone*</label>
                    <base-input alternative class="input-group-alternative mb-3 p-0" placeholder="Phone" addon-left-icon="ni ni-mobile-button" v-model="person.phone" name="Phone" required> </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label>Aadhar Number*</label>
                    <base-input alternative class="input-group-alternative mb-3 p-0" placeholder="12 Digit Aadhar Number" minlength="12" maxlength="12" addon-left-icon="ni ni-collection" v-model="person.aadhar" name="Aadhar" required> </base-input>
                  </div>
                  <div class="col-md-6">
                    <button class="btn btn-warning btn-sm float-right" @click.prevent="delAccompanied(index)"><i class="fa fa-times"></i></button>
                  </div>
                </div>
              </div>
            </fieldset>

            <div class="text-center">
              <base-button type="primary" native-type="submit" class="my-4" :disabled="loading"><i v-if="loading" class="fas fa-spinner fa-spin mr-1"></i> Register</base-button>
            </div>

            <base-alert v-if="alert_show" :type="alert_type" v-html="alert_msg"></base-alert>
          </form>

          <div class="print" v-if="qr_code_token != ''">
            <p class="text-center">Make screenshot of this <strong>QR Code</strong> or remember the <strong>10 Character Code</strong> below to show at Entrance of Pratishthan, Pune.</p>
            <img class="qrcode mx-auto d-block" :src="base_url + '/codes/' + qr_code_token + '.png'" />
            <base-input alternative class="qr_code_token input-group-alternative my-5" readonly v-model="qr_code_token"> </base-input>
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{{ model.name }}</td>
                </tr>
                <tr>
                  <th>Date of Birth</th>
                  <td>{{ model.dob }}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{{ model.email }}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{{ model.phone }}</td>
                </tr>
                <tr>
                  <th>Aadhar</th>
                  <td>{{ model.aadhar }}</td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td>
                    {{ model.address + ", " + model.city + ", " + model.state + ", " + model.country }}
                  </td>
                </tr>
                <tr>
                  <th>Center name</th>
                  <td>{{ model.center_name }}</td>
                </tr>
                <tr>
                  <th>Center Coordinator</th>
                  <td>{{ model.center_coo }}</td>
                </tr>
                <tr>
                  <th>Date of Visit</th>
                  <td>{{ model.date_visit }}</td>
                </tr>
                <tr>
                  <th>Visit Time Slot</th>
                  <td>{{ model.time_slot == "Morning" ? "Morning (10 AM to 1 PM)" : "Evening (3 PM to 6 PM)" }}</td>
                </tr>
                <tr>
                  <th>Photo</th>
                  <td>{{ model.photo }}</td>
                </tr>
                <tr>
                  <th>Accompanied</th>
                  <td>
                    <ol>
                      <li :key="accompany.id" v-for="accompany in model.accompaniedObj">
                        {{ accompany.name + " (" + accompany.phone + ") " + accompany.aadhar }}
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <th>Approved</th>
                  <td>True</td>
                </tr>
              </tbody>
            </table>
            <button class="btn btn-sm btn-primary mx-auto d-block mt-3" @click.prevent="printPage">
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "../../config.js";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import axios from "axios";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "login",
  components: {
    ValidationError,
    flatPicker,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    model: {
      name: "",
      dob: "01-06-1985",
      email: "",
      phone: "",
      aadhar: "",
      country: "India",
      state: "",
      city: "",
      address: "",
      center_name: "",
      center_coo: "",
      date_visit: "",
      time_slot: "Morning",
      accompaniedObj: [
        {
          id: 1,
          name: "",
          phone: "",
          aadhar: "",
        },
      ],
      accompanied: "",
    },
    model2: {
      name: "Ganesh Bhosale",
      dob: "27-12-1988",
      email: "ganesh@dwij.in",
      phone: "+917350558900",
      aadhar: "123412341234",
      country: "India",
      state: "Maharashtra",
      city: "Pune",
      address: "Lakeshire, Ambagaon Kh, Pune",
      center_name: "Pratishthan",
      center_coo: "Sahja Yogi",
      date_visit: "",
      time_slot: "Morning",
      accompaniedObj: [
        {
          id: 1,
          name: "Ganesh Bhosale",
          phone: "+917350558900",
          aadhar: "123412341234",
        },
        {
          id: 2,
          name: "Purnima Yadav",
          phone: "+917350844605",
          aadhar: "123412341235",
        },
      ],
      accompanied: "",
    },
    base_url: "https://app.pratishthanpune.in",
    qr_code_token: "",
    countryOptions: config.country_codes,
    timeSlotOptions: [
      { text: "Morning (10 AM to 1 PM)", value: "Morning" },
      // { text: "Afternoon", value: "Afternoon" },
      { text: "Evening (3 PM to 6 PM)", value: "Evening" },
    ],
    configBirthDate: {
      dateFormat: "d-m-Y",
      maxDate: new Date().fp_incr(-365 * 10),
    },
    configVisitDate: {
      dateFormat: "d-m-Y",
      minDate: "08-10-2021",
      maxDate: new Date().fp_incr(30),
    },
    alert_show: false,
    alert_type: "danger",
    alert_msg: "",
  }),
  mounted() {
    this.base_url = process.env.VUE_APP_BASE_URL;
  },
  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated();
    },
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      this.model.accompanied = JSON.stringify(this.model.accompaniedObj);

      const model = { ...this.model };

      delete model.accompaniedObj;

      console.log(JSON.stringify(model));
      const data = JSON.stringify(model);
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: process.env.VUE_APP_API_BASE_URL + "/access/visit-register",
        data: data,
      }).then(
        (response) => {
          this.loading = false;
          console.log(response);
          if (response.data.success == true) {
            this.alert_type = "success";
            this.alert_msg = "Registration Successful !!!";
            this.showAlert();
            this.qr_code_token = response.data.qr_code_token;
          } else {
            this.alert_type = "danger";
            this.alert_msg = "Registration Failed: " + response.data.message;
            this.showAlert();
          }
        },
        (error) => {
          this.loading = false;
          console.log("ERROR", error.response.data);
          this.$notify({
            type: "danger",
            message: error.response.data.message,
          });
          var msgs = "";
          error.response.data.errors.forEach((error) => {
            msgs = msgs + error + "<br>";
          });
          this.alert_type = "danger";
          this.alert_msg = msgs;
          this.showAlert();
        }
      );
    },
    addAccompanied() {
      if (this.model.accompaniedObj.length > 3) {
        return false;
      }
      this.model.accompaniedObj.push({
        id: this.model.accompaniedObj.length + 2,
        name: "",
        phone: "",
        aadhar: "",
      });
    },
    delAccompanied(index) {
      this.model.accompaniedObj.splice(index, 1);
    },
    showAlert() {
      this.alert_show = true;
    },
    printPage() {
      window.print();
    },
  },
};
</script>

<style lang="css">
label {
  font-size: 13px;
}

fieldset {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

fieldset legend {
  font-size: 18px;
  width: auto;
  padding: 0px 10px;
}

img.qrcode {
  width: 200px;
  max-width: 100%;
}

.qr_code_token input {
  font-size: 30px;
  text-align: center;
  color: #000;
  letter-spacing: 0.4em;
}

.print ol {
  padding: 0px 10px;
  margin: 0px;
}

.flatpickr-input {
  background-color: #ffffff !important;
}

@media print {
  .header {
    display: none;
  }

  .container {
    margin-top: 0px !important;
  }

  .card {
  }

  .copyright,
  .btn-primary {
    display: none;
  }
}
</style>
