<template>
  <card header-classes="widget-calendar bg-transparent">
    <div slot="header" class="row align-items-center">
      <div slot="header" class="row align-items-center">
        <div class="col">
          <h6 class="text-uppercase text-muted ls-1 mb-1">{{ year }}</h6>
          <h5 class="h3 mb-0">{{ today }}</h5>
        </div>
      </div>
    </div>

    <div>
      <full-calendar :events="events" :plugins="calendarPlugins" :editable="true" contentHeight="auto" :theme="false" ref="fullCalendar" :defaultView="defaultView"> </full-calendar>
    </div>
  </card>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import format from "date-fns/format";
import axios from "axios";

const today = new Date();
export default {
  name: "calendar",
  components: {
    FullCalendar,
  },
  data() {
    return {
      calendarPlugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      defaultView: "dayGridMonth",
      year: today.getFullYear(),
      today: format(today, "dddd, MMM DD"),
      events: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: process.env.VUE_APP_API_BASE_URL + "/access/fetch-calendar",
        data: {},
      }).then(
        (response) => {
          // console.log(response);
          if (response.data.success == true) {
            this.events = response.data.data;
            console.log("this.events", this.events);
          }
        },
        (error) => {
          console.log("ERROR", error.response.data);
          this.$notify({
            type: "danger",
            message: error.response.data.message,
          });
        }
      );
    },
  },
};
</script>
<style lang="scss">
@import "~@fullcalendar/core/main.css";
@import "~@/assets/scss/core/vendors/fullcalendar";
</style>
