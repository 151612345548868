<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <base-input
            alternative
            type="date"
            class="input-group-alternative mb-3"
            placeholder="Date of Visit"
            addon-left-icon="ni ni-calendar-grid-58"
            v-model="model.date_visit"
            name="Date of Visit"
          >
          </base-input>
        </div>
        <div class="col-xl-3 col-lg-6 text-center">
          <base-radio
            :key="slot.value"
            v-for="slot in timeSlotOptions"
            :name="slot.value"
            class="mt-2"
            v-model="model.time_slot"
            >{{ slot.text }}</base-radio
          >
        </div>
        <div class="col-xl-3 col-lg-6">
          <button
            class="btn btn-sm btn-secondary mt-1"
            @click.prevent="fetchData()"
          >
            Fetch
          </button>
        </div>
        <div class="col-xl-3 col-lg-6 text-right">
          <button
            class="btn btn-md btn-warning mt-1"
            @click.prevent="printPage"
          >
            Print
          </button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
            <div
              class="card-header border-0"
              :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                    Visitors / {{ model.date_visit }} / {{ model.time_slot }}
                  </h3>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <base-table
                class="table align-items-center table-flush"
                :class="type === 'dark' ? 'table-dark' : ''"
                :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
                tbody-classes="list"
                :data="tableData"
              >
                <template slot="columns">
                  <th>Code</th>
                  <th>Name</th>
                  <th>DOB</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Aadhar</th>
                  <th>Address</th>
                  <th>center</th>
                  <th>accompanied</th>
                  <th></th>
                </template>

                <template slot-scope="{ row }">
                  <th scope="row">
                    <span class="name mb-0 text-sm">{{
                      row.qr_code_token
                    }}</span>
                  </th>
                  <th scope="row">
                    <span class="name mb-0 text-sm">{{ row.name }}</span>
                  </th>
                  <th scope="row">
                    <span class="dob mb-0 text-sm">{{ row.dob }}</span>
                  </th>
                  <th scope="row">
                    <span class="email mb-0 text-sm">{{ row.email }}</span>
                  </th>
                  <th scope="row">
                    <span class="phone mb-0 text-sm">{{ row.phone }}</span>
                  </th>
                  <th scope="row">
                    <span class="aadhar mb-0 text-sm">{{ row.aadhar }}</span>
                  </th>
                  <th scope="row">
                    <span
                      class="address mb-0 text-sm"
                      v-html="
                        row.address +
                        ',<br>' +
                        row.city +
                        ', ' +
                        row.state +
                        ', ' +
                        row.country
                      "
                    ></span>
                  </th>
                  <th scope="row">
                    <span class="center_name mb-0 text-sm">{{
                      row.center_name
                    }}</span>
                  </th>
                  <th scope="row">
                    <span class="accompanied mb-0 text-sm">
                      <ul>
                        <li :key="person.id" v-for="person in row.accompanied">
                          {{
                            person.name +
                            " (" +
                            person.phone +
                            ") " +
                            person.aadhar
                          }}
                        </li>
                      </ul>
                    </span>
                  </th>

                  <td class="text-right"></td>
                </template>
              </base-table>
            </div>

            <div
              class="card-footer d-flex justify-content-end"
              :class="type === 'dark' ? 'bg-transparent' : ''"
              v-if="false"
            >
              <base-pagination total="30"></base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "tables",
  components: {},
  data: () => ({
    type: "light",
    model: {
      date_visit: "",
      time_slot: "Morning",
    },
    timeSlotOptions: [
      { text: "Morning", value: "Morning" },
      // { text: "Afternoon", value: "Afternoon" },
      { text: "Evening", value: "Evening" },
    ],
    tableData: [],
  }),
  mounted() {
    var today = new Date();
    this.model.date_visit = today.toISOString().split("T")[0];
    // console.log(this.model.date_visit);
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const data = JSON.stringify(this.model);
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: process.env.VUE_APP_API_BASE_URL + "/access/fetch-visitors",
        data: data,
      }).then(
        (response) => {
          // console.log(response);
          if (response.data.success == true) {
            this.tableData = response.data.data;
            // console.log("this.tableData", this.tableData);
          }
        },
        (error) => {
          console.log("ERROR", error.response.data);
          this.$notify({
            type: "danger",
            message: error.response.data.message,
          });
        }
      );
    },
    printPage() {
      window.print();
    },
  },
};
</script>
<style>
.custom-radio {
  display: inline-block;
  margin-right: 30px;
}
.accompanied ul {
  padding: 0px 10px;
  margin: 0px;
}
.card .table td,
.card .table th {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media print {
  #sidenav-main {
    display: none;
  }
  .main-content {
    margin: 0px !important;
  }
  .navbar,
  .header {
    display: none;
  }
  .container-fluid {
    margin-top: 0px !important;
    top: 0px;
    left: 0px;
    padding: 0px !important;
    margin-left: -37px;
  }
  .footer {
    display: none;
  }
  .card.shadow {
    box-shadow: none !important;
    border: 0px;
    padding: 0px !important;
    margin: 0px !important;
  }
  .copyright,
  .btn-primary {
    display: none;
  }
  .card .table {
    max-width: 100%;
    padding: 0px;
    margin: 0px;
  }
  .table-responsive {
    overflow-x: visible;
  }
  h3 {
    text-align: center;
  }
  .card .table td,
  .card .table th {
    color: #000;
  }
}
</style>
